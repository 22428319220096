import { makeStyles, shorthands } from '@fluentui/react-components';
import { FC } from 'react';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { ChatWindow } from '../chat/ChatWindow';
import { AppState } from '../../AppState';

const useClasses = makeStyles({
    container: {
        ...shorthands.overflow('hidden'),
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'start',
        height: '100%',
    },
});

interface IChatViewProps {
    setAppState: React.Dispatch<React.SetStateAction<AppState>>;
}

export const ChatView: FC<IChatViewProps> = ({ setAppState }) => {
    const classes = useClasses();
    useAppSelector((state: RootState) => state.conversations);

    return (
        <div className={classes.container}>
            <ChatWindow setAppState={setAppState} />
        </div>
    );
};
