import React, { PropsWithChildren } from 'react';
import { useOidc } from './OidcProvider';

export function OidcAuthenticatedTemplate({ children }: PropsWithChildren): React.ReactElement | null {
    const { user } = useOidc();

    if (user) {
        return <React.Fragment>{children}</React.Fragment>;
    }
    return null;
}
