import React from 'react';
import { useTranslation } from 'react-i18next';
import { setHostContext } from '../../redux/features/hostContext/hostContextSlice';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import {
    Menu,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    makeStyles,
    mergeClasses,
} from '@fluentui/react-components';

const useClasses = makeStyles({
    languageButton: {
        fontWeight: 'bold',
    },
});

export const LanguageSwitcher: React.FC = () => {
    const { i18n } = useTranslation();
    const classes = useClasses();
    const dispatch = useAppDispatch();
    const hostContext = useAppSelector((state: RootState) => state.hostContext.hostContext);

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng).catch((e: unknown) => {
            console.error('Error changing language', e);
        });

        const newCtx = { ...hostContext, language: lng };
        dispatch(setHostContext(newCtx));
        localStorage.setItem('hostContext', JSON.stringify(newCtx));
        return true;
    };
    return (
        <Menu>
            <MenuTrigger disableButtonEnhancement>
                <MenuItem>Language 🇸🇪🇬🇧🇳🇱</MenuItem>
            </MenuTrigger>

            <MenuPopover>
                <MenuList>
                    <MenuItem
                        className={mergeClasses(i18n.language === 'en' && classes.languageButton)}
                        onClick={() => changeLanguage('en')}
                    >
                        English
                    </MenuItem>
                    <MenuItem
                        className={mergeClasses(i18n.language === 'sv' && classes.languageButton)}
                        onClick={() => changeLanguage('sv')}
                    >
                        Svenska
                    </MenuItem>
                    <MenuItem
                        className={mergeClasses(i18n.language === 'nl' && classes.languageButton)}
                        onClick={() => changeLanguage('nl')}
                    >
                        Nederlands
                    </MenuItem>
                </MenuList>
            </MenuPopover>
        </Menu>
    );
};
