import { HostContext } from '../../embed-script/HostContext';
import { FeedbackData } from '../models/FeedbackData';
import { BaseService } from './BaseService';

export class FeedbackService extends BaseService {
    public postFeedbackAsync = async (
        feedbackData: FeedbackData,
        accessToken: string,
        hostContext?: HostContext,
    ): Promise<void> => {
        await this.getResponseAsync(
            {
                commandPath: 'feedback',
                method: 'POST',
                body: feedbackData,
                hostContext,
            },
            accessToken,
        );
    };
}
