import { Button, Text, Tooltip, makeStyles } from '@fluentui/react-components';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useChat } from '../../../libs/hooks/useChat';
import { IChatMessage, UserFeedback } from '../../../libs/models/ChatMessage';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { updateMessageProperty } from '../../../redux/features/conversations/conversationsSlice';
import { ThumbDislike16, ThumbLike16 } from '../../shared/BundledIcons';
import { ThumbDislike16Filled, ThumbLike16Filled } from '@fluentui/react-icons';
import { setFeedbackIsOpen, setFeedbackIsPositive, setFeedbackContext } from '../../../redux/features/app/appSlice';
import { ContentMetadata } from '../../../libs/models/ContentMetadata';

const useClasses = makeStyles({
    root: {
        display: 'flex',
        'place-content': 'flex-end',
        alignItems: 'center',
    },
});

interface IUserFeedbackProps {
    message: IChatMessage;
}

export const UserFeedbackActions: React.FC<IUserFeedbackProps> = ({ message }) => {
    const classes = useClasses();
    const chat = useChat();

    const dispatch = useAppDispatch();
    const { selectedId } = useAppSelector((state: RootState) => state.conversations);

    const onUserFeedbackProvided = useCallback(
        (positive: boolean) => {
            dispatch(setFeedbackIsPositive(positive));

            const messageId: string = message.id as string;
            const contentMetadata: ContentMetadata[] | undefined = message.contentMetadataArray?.contentMetadata;
            dispatch(setFeedbackContext({ chatId: selectedId, messageId, contentMetadata }));
            dispatch(setFeedbackIsOpen({ isOpen: true, hideRating: true }));
            try {
                chat.userFeedbackOnMessage(
                    message.chatId,
                    message.id as string,
                    positive ? UserFeedback.Positive : UserFeedback.Negative,
                ).catch(() => null);
                dispatch(
                    updateMessageProperty({
                        chatId: selectedId,
                        messageIdOrIndex: message.id as string,
                        property: 'userFeedback',
                        value: positive ? UserFeedback.Positive : UserFeedback.Negative,
                        frontLoad: true,
                    }),
                );
            } catch (_) {
                // handle error here
            }
        },
        [chat, dispatch, message, selectedId],
    );

    const likeIcon = message.userFeedback == UserFeedback.Positive ? <ThumbLike16Filled /> : <ThumbLike16 />;
    const dislikeIcon = message.userFeedback == UserFeedback.Negative ? <ThumbDislike16Filled /> : <ThumbDislike16 />;

    const { t } = useTranslation();

    return (
        <div className={classes.root}>
            <Text color="gray" size={200}></Text>
            <Tooltip content={t('userFeedbackActions.likeDescription')} relationship="label">
                <Button
                    icon={likeIcon}
                    appearance="transparent"
                    aria-label="Edit"
                    onClick={() => {
                        onUserFeedbackProvided(true);
                    }}
                />
            </Tooltip>
            <Tooltip content={t('userFeedbackActions.dislikeDescription')} relationship="label">
                <Button
                    icon={dislikeIcon}
                    appearance="transparent"
                    aria-label="Edit"
                    onClick={() => {
                        onUserFeedbackProvided(false);
                    }}
                />
            </Tooltip>
        </div>
    );
};
