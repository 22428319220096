import { UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';

const voidcSettings: UserManagerSettings = {
    accessTokenExpiringNotificationTimeInSeconds: 240,
    metadataUrl: process.env.REACT_APP_OPENIDCONNECT_METADATA_URL,
    authority: process.env.REACT_APP_OPENIDCONNECT_AUTHORITY ?? '',
    client_id: process.env.REACT_APP_OPENIDCONNECT_CLIENT_ID ?? '',
    redirect_uri: process.env.REACT_APP_OPENIDCONNECT_REDIRECT_URI ?? '',
    post_logout_redirect_uri: process.env.REACT_APP_OPENIDCONNECT_REDIRECT_URI,
    response_type: 'code',
    scope: `openid profile email roles ${process.env.REACT_APP_OPENIDCONNECT_API_SCOPE ?? ''} offline_access`,
    userStore: new WebStorageStateStore({ store: window.sessionStorage }), // is type-annotated by definition
};

export const OidcAuthHelper = {
    voidcSettings,
};
