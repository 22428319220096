import { makeStyles, shorthands } from '@fluentui/react-components';
import React from 'react';

const useClasses = makeStyles({
    askButton: {
        textAlign: 'center',
        ...shorthands.margin('4px'),
        ...shorthands.padding('10px'),
        userSelect: 'none',
        ...shorthands.borderStyle('solid'),
        ...shorthands.borderWidth('1px'),
        ...shorthands.borderColor('#2D7048'),
        cursor: 'pointer',
        backgroundColor: '#f0faf0', // Add this line
        color: '#000000',
        paddingTop: '1px',
        paddingBottom: '1px',
        ...shorthands.borderRadius('7px'),
        '&selectors': {
            '&:empty': {
                visibility: 'hidden',
            },
            '@media(max-width: 899px)': {},
        },
        ':hover': {
            backgroundColor: '#2D7048',
            color: '#FFFFFF',
        },
    },
    askButtonRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'right',

        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap',
        '&selectors': {
            '@media(max-width: 899px)': {
                flexDirection: 'column',
            },
        },
    },
});

interface Props {
    items?: string[];
    onClick: (text: string) => void;
}

export const ChatSupplementaryQuestions: React.FC<Props> = ({ items, onClick }) => {
    const classes = useClasses();
    if (items === undefined || items.length === 0) {
        return null;
    }
    return (
        <div className={`${classes.askButtonRow} `}>
            {items.map((text, index) => (
                <div
                    key={index}
                    className={classes.askButton}
                    onClick={() => {
                        onClick(text);
                    }}
                >
                    {text}
                </div>
            ))}
        </div>
    );
};
