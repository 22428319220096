import { makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components';
import React from 'react';
import { AuthorRoles, IChatMessage } from '../../../libs/models/ChatMessage';
import { ChatHistoryItem } from './ChatHistoryItem';
import ScrollingCardList from './ScrollingCardList';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { ChatStatusCard } from '../ChatStatusCard';
import { BotResponseStatus } from '../../../redux/features/conversations/ChatState';

const useClasses = makeStyles({
    root: {
        ...shorthands.gap(tokens.spacingVerticalM),
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '900px',
        width: '100%',
        justifySelf: 'center',
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
    },
    messageWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start',
        ...shorthands.gap('1rem'),
        marginBottom: '1rem',
    },
    bot: {
        alignSelf: 'end',
        width: '100%',
    },
});

interface ChatHistoryProps {
    onQuerySubmit: (query: string) => void;
    statuses: BotResponseStatus[];
}

export const ChatHistory: React.FC<ChatHistoryProps> = ({ onQuerySubmit, statuses }) => {
    const classes = useClasses();
    const isBot = (message: IChatMessage) => message.authorRole === AuthorRoles.Bot;
    const { ShowScrollingCards, V2ExperimentalFeatures } = useAppSelector(
        (state: RootState) => state.app.serviceInfo.featureFlags,
    );
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const messages = conversations[selectedId].messages;
    const hasStartedTyping = conversations[selectedId].input != '';

    return (
        <div className={classes.root}>
            {messages.map((message, index) => (
                <div
                    key={message.timestamp}
                    className={
                        isBot(message) ? mergeClasses(classes.messageWrapper, classes.bot) : classes.messageWrapper
                    }
                >
                    <ChatHistoryItem message={message} messageIndex={index} />
                </div>
            ))}
            {ShowScrollingCards && messages.length === 1 && !hasStartedTyping && (
                <ScrollingCardList onQuerySubmit={onQuerySubmit} />
            )}
            {V2ExperimentalFeatures && <ChatStatusCard statuses={statuses} />}
        </div>
    );
};
