import { ApplicationInsights, ICustomProperties } from '@microsoft/applicationinsights-web';

let appInsights: ApplicationInsights | null = null;

export function init() {
    if (process.env.REACT_APP_APPINSIGHTS_CONNECTIONSTRING) {
        try {
            appInsights = new ApplicationInsights({
                config: {
                    connectionString: process.env.REACT_APP_APPINSIGHTS_CONNECTIONSTRING,
                },
            });
            appInsights.loadAppInsights();
            appInsights.trackPageViewPerformance({});
            appInsights.addTelemetryInitializer((envelope) => {
                if (envelope.baseType === 'ExceptionData') {
                    if (
                        envelope.baseData &&
                        typeof envelope.baseData.exceptions === 'undefined' &&
                        envelope.baseData.message &&
                        (envelope.baseData.message as string).includes('Cannot redefine property: googletag')
                    ) {
                        return false;
                    }
                }
                return true;
            });

            // override console.log and the other console logging methods
            function wrapConsoleMethod(method: 'log' | 'info' | 'warn' | 'error', severityLevel: number) {
                const originalMethod = console[method];
                console[method] = function (message: string, ...optionalParams: Array<Record<string, unknown>>) {
                    if (process.env.NODE_ENV === 'development') {
                        originalMethod.apply(console, [message, ...optionalParams]);
                    }
                    const customProperties: ICustomProperties = optionalParams.reduce<ICustomProperties>(
                        (acc, curr, index) => {
                            acc[`param${(index + 1).toString()}`] = curr;
                            return acc;
                        },
                        {},
                    );
                    appInsights?.trackTrace({ message, severityLevel }, customProperties);
                };
            }

            wrapConsoleMethod('log', 0);
            wrapConsoleMethod('info', 1);
            wrapConsoleMethod('warn', 2);
            wrapConsoleMethod('error', 3);
        } catch (e) {
            console.error('Failed to initialize app insights', e);
        }
    }
}

export function trackEvent(name: string, properties?: Record<string, any>) {
    if (process.env.NODE_ENV === 'development') {
        console.log(`Tracking event: ${name}, appInsights:defined: ${(appInsights !== null).toString()}`, properties);
    }
    appInsights?.trackEvent({ name, properties });
}
