import { AppSpecificConstants } from './Constants';

const Constants: AppSpecificConstants = {
    app: {
        name: 'AI-assistent',
    },
    bot: {
        profile: {
            fullName: 'AI-assistent',
        },
    },
};

export default Constants;
