import React, { PropsWithChildren } from 'react';
import { useOidc } from './OidcProvider';

export function OidcUnauthenticatedTemplate({ children }: PropsWithChildren): React.ReactElement | null {
    const { user } = useOidc();

    if (user == null) {
        return <React.Fragment>{children}</React.Fragment>;
    }
    return null;
}
