import { EndSessionRequest, IPublicClientApplication, LogLevel } from '@azure/msal-browser';
import debug from 'debug';
import { Constants } from '../../Constants';

const log = debug(Constants.debug.root).extend('authHelper');

const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_AAD_CLIENT_ID ?? '',
        authority: process.env.REACT_APP_AAD_AUTHORITY,
        redirectUri: window.origin,
    },
    knownAuthorities: [process.env.REACT_APP_AAD_AUTHORITY],
    cache: Constants.msal.cache,
    system: {
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        log('error:', message);
                        return;
                    case LogLevel.Info:
                        log('info:', message);
                        return;
                    case LogLevel.Verbose:
                        log('verbose:', message);
                        return;
                    case LogLevel.Warning:
                        log('warn:', message);
                        return;
                    default:
                        log(message);
                }
            },
        },
        windowHashTimeout: 9000, // Applies just to popup calls - In milliseconds
        iframeHashTimeout: 9000, // Applies just to silent calls - In milliseconds
        loadFrameTimeout: 9000, // Applies to both silent and popup calls - In milliseconds
    },
};

const logoutRequest: EndSessionRequest = {
    postLogoutRedirectUri: window.origin,
};

const ssoSilentRequest = async (msalInstance: IPublicClientApplication) => {
    await msalInstance.ssoSilent({
        account: msalInstance.getActiveAccount() ?? undefined,
        scopes: Constants.msal.semanticKernelScopes,
    });
};

const loginAsync = async (instance: IPublicClientApplication) => {
    if (Constants.msal.method === 'redirect') {
        await instance.loginRedirect({
            scopes: Constants.msal.semanticKernelScopes,
            extraScopesToConsent: Constants.msal.msGraphAppScopes,
        });
    } else {
        await instance.loginPopup({
            scopes: Constants.msal.semanticKernelScopes,
            extraScopesToConsent: Constants.msal.msGraphAppScopes,
        });
    }
};

const logoutAsync = (instance: IPublicClientApplication) => {
    if (Constants.msal.method === 'popup') {
        void instance.logoutPopup(logoutRequest);
    }
    if (Constants.msal.method === 'redirect') {
        void instance.logoutRedirect(logoutRequest);
    }
};

export const MsalAuthHelper = {
    msalConfig,
    logoutRequest,
    ssoSilentRequest,
    loginAsync,
    logoutAsync,
};
