/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import styled from 'styled-components';
import { Button } from '@fluentui/react-components';
import { DialogActions } from '@fluentui/react-components';

export const FlexDialogActions = styled(DialogActions)`
    flex-direction: row;
    justify-self: flex-end;
`;

export const PrimaryButton = styled(Button)`
    padding: 10px 20px;
    border: none;
    background-color: var(--green-70);
    color: var(--neutral-05);
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.4s;

    &:hover {
        background-color: var(--green-80);
    }

    &:active {
        background-color: var(--green-90);
    }

    &:focus {
        box-shadow: 0 0 0 2px var(--neutral-05) inset;
        outline: none;
    }
`;

export const SecondaryButton = styled(Button)`
    padding: 10px 20px;
    border: 1px solid var(--neutral-60);
    background-color: var(--neutral-05);
    color: var(--neutral-90);
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    transition:
        border-color 0.4s,
        background-color 0.4s;

    &:hover {
        border-color: var(--spcs-purple-60);
        background-color: var(--spcs-purple-05);
    }

    &:focus {
        border-color: var(--spcs-purple-60);
        border-width: 2px;
        background-color: var(--spcs-purple-05);
        outline: none;
    }
`;

export const SpinnerContainer = styled('div')`
    width: 100%;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(0.5);
`;
