import { FC, useCallback } from 'react';

import { useMsal } from '@azure/msal-react';
import {
    Avatar,
    Menu,
    MenuDivider,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Persona,
    makeStyles,
    shorthands,
    tokens,
} from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';

import { AuthHelper, AuthType } from '../../libs/auth/AuthHelper';
import { MsalAuthHelper } from '../../libs/auth/MsalAuthHelper';
import { useChat } from '../../libs/hooks/useChat';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState, resetState } from '../../redux/app/store';
import { useOidc } from '../views/OidcProvider';
import { HostContext } from '../../embed-script/HostContext';
import { LanguageSwitcher } from './LanguageSwitcher';

export const useClasses = makeStyles({
    root: {
        marginRight: tokens.spacingHorizontalXL,
    },
    persona: {
        ...shorthands.padding(tokens.spacingVerticalM, tokens.spacingVerticalMNudge),
        overflowWrap: 'break-word',
    },
});

interface IUserSettingsProps {
    setLoadingState: () => void;
    hostContext?: HostContext;
}

export const UserSettingsMenu: FC<IUserSettingsProps> = ({ setLoadingState, hostContext }) => {
    const classes = useClasses();
    const { instance } = useMsal();
    const chat = useChat();
    const oidcContext = useOidc();
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);

    const onLogout = useCallback(() => {
        setLoadingState();
        if (AuthHelper.getAuthType() === AuthType.OIDC) {
            if (!oidcContext.userManager) throw new Error('User manager is not set when using OpenIdConnect auth');
            oidcContext.userManager
                .signoutRedirect()
                .then(() => {
                    localStorage.setItem('oidcAutoLastLogin', new Date().toISOString());
                })
                .catch(() => {});
        } else {
            MsalAuthHelper.logoutAsync(instance);
        }
        resetState();
    }, [instance, oidcContext.userManager, setLoadingState]);

    const { t } = useTranslation();

    const onNewChat = useCallback(() => {
        setLoadingState();
        if (AuthHelper.getAuthType() === AuthType.OIDC) {
            chat.createChat(hostContext)
                .then(() => {})
                .catch(() => {});
        } else {
            chat.createChat(hostContext)
                .then(() => {
                    // TODO: Must be a more effectiveway to reload to show the new chat than this
                    instance
                        .loginRedirect()
                        .then(() => {})
                        .catch(() => {});
                })
                .catch(() => {})
                .finally(() => {});
        }
    }, [chat, setLoadingState, instance, hostContext]);

    return (
        <>
            {
                <Menu>
                    <MenuTrigger disableButtonEnhancement>
                        {
                            <Avatar
                                className={classes.root}
                                key={activeUserInfo?.username}
                                name={activeUserInfo?.username}
                                color={'brand'}
                                size={28}
                                badge={undefined}
                                data-testid="userSettingsButton"
                            />
                        }
                    </MenuTrigger>
                    <MenuPopover>
                        <MenuList>
                            <Persona
                                className={classes.persona}
                                name={activeUserInfo?.username}
                                secondaryText={activeUserInfo?.email}
                                presence={undefined}
                                avatar={{ color: 'brand' }}
                            />
                            <MenuDivider />
                            <MenuItem data-testid="createChatMenuButton" onClick={onNewChat}>
                                {t('userSettingsMenu.newChat')}
                            </MenuItem>
                            <LanguageSwitcher />
                            <MenuItem data-testid="logOutMenuButton" onClick={onLogout}>
                                {t('userSettingsMenu.logOut')}
                            </MenuItem>
                        </MenuList>
                    </MenuPopover>
                </Menu>
            }
        </>
    );
};
