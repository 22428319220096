import React, { useEffect, useState, createContext, useContext, PropsWithChildren } from 'react';
import { UserManager, User } from 'oidc-client-ts';

export interface IOidcContext {
    user: User | null;
    userManager: UserManager | undefined;
    isInitialized: boolean;
}

let currentUser: User | null;
let currentUserManager: UserManager | undefined;

const OidcContext = createContext<IOidcContext | null>(null);

export const OidcProvider: React.FC<PropsWithChildren<{ userManager: UserManager }>> = ({ userManager, children }) => {
    const [user, setUser] = useState<User | null>(null);

    const handleUserUnloaded = () => {
        currentUser = null;
        setUser(null);
    };

    useEffect(() => {
        void userManager
            .getUser()
            .then((user) => {
                if (user?.access_token && !user.expired) {
                    currentUser = user;
                    setUser(user);
                }
            })
            .catch(null);

        userManager.events.addUserLoaded(setUser);
        userManager.events.addUserUnloaded(handleUserUnloaded);

        return () => {
            userManager.events.removeUserLoaded(setUser);
            userManager.events.removeUserUnloaded(handleUserUnloaded);
        };
    }, [userManager]);

    return <OidcContext.Provider value={{ user, userManager, isInitialized: true }}>{children}</OidcContext.Provider>;
};

export function useOidc(): IOidcContext {
    const auth = useContext(OidcContext);
    if (!auth) {
        currentUserManager = undefined;
        return {
            isInitialized: false,
            user: null,
            userManager: currentUserManager,
        };
    }
    currentUserManager = auth.userManager;
    return { ...auth, isInitialized: true };
}

export const setCurrentUser = (user: User | null) => {
    currentUser = user;
};

export const getCurrentUser = (): User | null => {
    return currentUser;
};

export const getUserManager = (): UserManager | undefined => {
    return currentUserManager;
};
