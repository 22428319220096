interface CheckboxProps {
    label: string;
    productName: string;
    isChecked: boolean;
    onChange: (productName: string, isChecked: boolean) => void;
}

const Checkbox: React.FC<CheckboxProps> = ({ label, productName, isChecked, onChange }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(productName, event.target.checked);
    };

    return (
        <label>
            <input type="checkbox" checked={isChecked} onChange={handleChange} />
            {label}
        </label>
    );
};

export default Checkbox;
