import { makeStyles } from '@fluentui/react-components';
import React from 'react';
import { ContentMetadata } from '../../../libs/models/ContentMetadata';
import { useTranslation } from 'react-i18next';

const useClasses = makeStyles({
    content: {
        wordBreak: 'break-word',
    },
});

interface ChatHistorySourceLinksProps {
    contentMetadata: undefined | ContentMetadata[];
}

export const ChatHistorySourceLinks: React.FC<ChatHistorySourceLinksProps> = ({ contentMetadata }) => {
    const classes = useClasses();
    const { t } = useTranslation();
    return (
        <div className={classes.content}>
            <div className={classes.content}>
                <b> {t('chathistorysourcelinks.references')}:</b>
                {contentMetadata
                    ?.filter((element) => {
                        return element.orderOfAppearance > 0;
                    })
                    .sort((a, b) => {
                        return a.orderOfAppearance - b.orderOfAppearance;
                    })
                    .map((data, index) => (
                        <p key={index}>
                            {data.orderOfAppearance}){' '}
                            <a href={data.url} rel="noreferrer" target="_blank">
                                <span dangerouslySetInnerHTML={{ __html: data.title as string }} />
                            </a>
                            <br></br>
                        </p>
                    ))}
            </div>
        </div>
    );
};
