export const ProductConstants = {
    MyServices: {
        ProductName: 'mina-tjanster',
    },
    EAccounting: {
        ProductName: 'eAccounting',
        Variants: {
            bookkeeping: 'bookkeeping',
            invoicing: 'invoicing',
            pro: 'pro',
            solo: 'solo',
            standard: 'standard',
        },
    },
    CloudPayroll: {
        ProductName: 'CloudPayroll',
        Variants: {
            smart: 'smart',
            direkt: 'direkt',
        },
        SmartProductName: 'visma-lon-smart',
        DirectProductName: 'visma-lon-direkt',
    },
    Advisor: {
        ProductName: 'Advisor',
        Variants: {
            connect: 'connect',
            standard: 'standard',
            start: 'start',
        },
    },
    ApyVpyETax: {
        ProductNameAPY: 'APY',
        ProductNameVPY: 'VPY',
        ProductNameETax: 'ETAX',
        ProductNameETax_SE_AB: 'ETAX_SE_AB',
        ProductNameETax_SE_EF: 'ETAX_SE_EF',

        IndexFilterNameAPY: 'visma-skatt-bokslut-pro',
        IndexFilterNameVPY: 'visma-skatt-bokslut-pro',
        IndexFilterNameETax_arsredovisning: 'visma-deklaration-arsredovisning',
        IndexFilterNameETax_arsbokslut: 'visma-deklaration-arsbokslut',
        EtaxVariants: {
            arsredovisning: 'arsredovisning',
            arsbokslut: 'arsbokslut',
        },
        ClientTypes: {
            SwedishAktiebolag: 'SE_AB',
            SwedishEnskildFirma: 'SE_EF',
            SwedishPrivatperson: 'SE_PP',
            SwedishEkonomiskForening: 'SE_EKFOR',
            SwedishBostadsrattsforening: 'SE_EKBRF',
            SwedishHandelsbolag: 'SE_HB',
        },
    },
    VismaAdminitration: {
        ProductName: 'VismaAdministration',
        Variants: {
            administration500: 'administration500',
            administration1000: 'administration1000',
            administration2000: 'administration2000',
        },
    },
    VismaLon: {
        ProductName: 'VismaLon',
        Variants: {
            vismalon300: 'vismalon300',
            vismalon600: 'vismalon300',
        },
    },
    VismaCompact: {
        ProductName: 'VismaCompact',
        Variants: {
            compact500: 'compact500',
            compact750: 'compact750',
            compact1000: 'compact1000',
            compact1500: 'compact1500',
            compact3000: 'compact3000',
        },
    },
    VismaTid: {
        ProductName: 'VismaTid',
        Variants: {
            onprem: 'onprem',
        },
    },
    VismaEnskildFirma: {
        ProductName: 'VismaEnskildFirma',
    },
};
