import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HostContext } from '../../../embed-script/HostContext';
import { HostContextState } from './hostContextState';

const initialHostContext: HostContext = {
    id: undefined,
    products: [],
    typeOfBusiness: undefined,
    isConsultant: undefined,
    activeBankIntegration: undefined,
    companyType: undefined,
    country: undefined,
    language: undefined,
    hostname: undefined,
};

const initialState: HostContextState = {
    hostContext: initialHostContext,
    isInitialized: false,
};

export const hostContextSlice = createSlice({
    name: 'hostContext',
    initialState,
    reducers: {
        setHostContext: (state, action: PayloadAction<HostContext>) => {
            state.hostContext = { ...state.hostContext, ...action.payload };
            state.isInitialized = true;
        },
    },
});

export const { setHostContext } = hostContextSlice.actions;

export default hostContextSlice.reducer;
