import React from 'react';
import { Card, Text, makeStyles } from '@fluentui/react-components';

// const ANIMATION_DURATION = 30; // Duration of the animation in seconds
const NUM_ROWS = 3; // Number of rows to display

const useStyles = makeStyles({
    card: {
        height: '50px',
        marginTop: '10px',
        marginBottom: '10px',
        marginRight: '10px',
        marginLeft: '10px',
        flexGrow: '0',
        flexShrink: '0',
        flexBasis: 'auto',
        width: 'fit-content',
    },
    cardContent: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '0',
        paddingBottom: '0',
        paddingLeft: '5px',
        paddingRight: '5px',
    },
    cardIcon: {
        marginRight: '10px',
    },
    scrollingRow: {
        display: 'flex',
        overflowX: 'hidden',
        width: '100%',
    },

    scrollingGrid: {
        marginLeft: '-50px',
        marginRight: '-40px',
    },
    scrollingContent: {
        display: 'flex',
        animationDuration: '20s',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'linear',
        animationName: {
            from: {
                transform: 'translateX(0)',
            },
            to: {
                transform: 'translateX(calc(-120%))',
            },
        },
    },
});

const mockData = [
    { id: 1, description: 'Hur bokför man årets resultat?', icon: '📊' },
    { id: 2, description: 'Hur skapar man en ny kundfaktura?', icon: '📄' },
    { id: 3, description: 'Hur ändrar man betalningsvillkor på fakturor?', icon: '💰' },
    { id: 4, description: 'Vad är skillnaden mellan ingående och utgående moms?', icon: '🔄' },
    { id: 5, description: 'Hur justerar man lagersaldo?', icon: '📦' },
    { id: 6, description: 'Hur skickar man kundfakturor via e-post?', icon: '📧' },
    { id: 7, description: 'Hur bokför man inköp som är betalda med kvitto?', icon: '🧾' },
    { id: 8, description: 'Vad betyder egen kontering?', icon: '❓' },
    { id: 9, description: 'Hur byter man till tidigare årsredovisningar?', icon: '📅' },
    { id: 10, description: 'Kan man importera bokförda uppgifter till kontons ingående saldon?', icon: '📥' },
    { id: 11, description: 'Hur gör man en balansräkning?', icon: '⚖️' },
    { id: 12, description: 'Hur bokför man en IT-tjänst?', icon: '💻' },
    { id: 13, description: 'Hur lägger man till nya företag som kunder?', icon: '🏢' },
    { id: 14, description: 'Måste man låsa bokföringsåret för att ändra det?', icon: '🔒' },
    { id: 15, description: 'Hur ändrar man ingående balans för pågående räkenskapsår?', icon: '🔄' },
    { id: 16, description: 'Hur ångrar man bokförda verifikationer?', icon: '⎌' },
    { id: 17, description: 'Vad är skillnaden på primärt företagskonto och vanligt företagskonto?', icon: '🏦' },
    { id: 18, description: 'Hur bokför man egen överföring mellan konton?', icon: '💸' },
    {
        id: 19,
        description: 'Varför visas priser exklusive moms trots att inställningen är inklusive moms?',
        icon: '🛒',
    },
    { id: 20, description: 'Hur gör man ett bokslut i tjänsten?', icon: '📖' },
    { id: 21, description: 'Hur lägger man till en ny leverantör?', icon: '🚚' },
    { id: 22, description: 'Hur skapar man en återkommande faktura?', icon: '🔁' },
    { id: 23, description: 'Vad är skillnaden mellan faktura och offert?', icon: '📑' },
    { id: 24, description: 'Hur skapar man en ny artikel i artikelregistret?', icon: '🆕' },
    { id: 25, description: 'Hur ser man rapporter över försäljning?', icon: '📈' },
    { id: 26, description: 'Hur registrerar man en inbetalning från kund?', icon: '💳' },
    { id: 27, description: 'Hur lägger man till en ny användare?', icon: '👥' },
    { id: 28, description: 'Vad är skillnaden mellan kund och kontakt?', icon: '🤝' },
    { id: 29, description: 'Hur ändrar man företagets uppgifter?', icon: '🏢' },
    { id: 30, description: 'Hur skapar man en ny projektmall?', icon: '🗂️' },
    { id: 31, description: 'Hur bokför man utlägg för anställda?', icon: '💶' },
    { id: 32, description: 'Hur skapar man en ny resultatenhet?', icon: '📊' },
    { id: 33, description: 'Vad är skillnaden mellan likviditetsprognos och balansräkning?', icon: '💹' },
    { id: 34, description: 'Hur skapar man en ny momskod?', icon: '💱' },
    { id: 35, description: 'Hur bokför man försäljning av inventarier?', icon: '🛒' },
    { id: 36, description: 'Hur skapar man en ny kundmall för återkommande fakturering?', icon: '🔄' },
    { id: 37, description: 'Vad är skillnaden mellan kundreskontra och leverantörsreskontra?', icon: '📒' },
    { id: 38, description: 'Hur bokför man inköp av inventarier?', icon: '🛍️' },
    { id: 39, description: 'Hur skapar man en ny lagerplats?', icon: '📍' },
    { id: 40, description: 'Hur ser man en översikt över företagets ekonomi?', icon: '📈' },
];
interface ScrollingCardProps {
    onClick: (description: string) => void;
    description: string;
    icon: string;
}

const ScrollingCard: React.FC<ScrollingCardProps> = ({ onClick, description, icon }) => {
    const styles = useStyles();

    return (
        <Card
            onClick={() => {
                onClick(description);
            }}
            className={styles.card}
        >
            <div className={styles.cardContent}>
                <Text className={styles.cardIcon}>{icon}</Text>
                <Text>{description}</Text>
            </div>
        </Card>
    );
};

interface ScrollingCardRowProps {
    data: typeof mockData;
    onClick: (description: string) => void;
}

const ScrollingCardRow: React.FC<ScrollingCardRowProps> = ({ data, onClick }) => {
    const styles = useStyles();

    const duplicatedData = [...data, ...data.map((item) => ({ ...item, id: `${item.id.toString()}-duplicate` }))];

    return (
        <div className={styles.scrollingRow}>
            <div className={styles.scrollingContent}>
                {duplicatedData.map((card) => (
                    <ScrollingCard key={card.id} description={card.description} icon={card.icon} onClick={onClick} />
                ))}
            </div>
        </div>
    );
};

interface ScrollingCardGridProps {
    onQuerySubmit: (description: string) => void;
}

const ScrollingCardGrid: React.FC<ScrollingCardGridProps> = ({ onQuerySubmit }) => {
    const styles = useStyles();

    // Ensure that there is enough data to slice into rows
    const rowData = Array.from({ length: NUM_ROWS }, (_, i) => {
        const start = i * 3;
        const end = start + 3;
        return start < mockData.length ? mockData.slice(start, Math.min(end, mockData.length)) : [];
    });

    return (
        <div className={styles.scrollingGrid}>
            {rowData.map((data) => {
                const key = data.map((item) => item.id).join('');
                return <ScrollingCardRow key={key} data={data} onClick={onQuerySubmit} />;
            })}
        </div>
    );
};

export default ScrollingCardGrid;
