import { IChatMessage } from '../../libs/models/ChatMessage';

interface Questions {
    aq: string[];
}

function extractJsonFromString(streamedString: string): [string, Questions?] {
    const startPos = streamedString.indexOf('{');
    if (startPos === -1) {
        return [streamedString, undefined]; // No JSON part found
    }
    const endPos = streamedString.lastIndexOf('}');
    if (endPos === -1) {
        return [streamedString.substring(0, startPos), undefined];
    }

    const substring = streamedString.substring(startPos, endPos + 1);
    const openBracesCount = substring.split('{').length - 1;
    const closeBracesCount = substring.split('}').length - 1;

    const nonJsonPart = streamedString.substring(0, startPos);

    if (openBracesCount === closeBracesCount) {
        try {
            const questions = JSON.parse(substring) as Questions;
            return [nonJsonPart, questions];
        } catch (_) {
            return [streamedString, undefined]; // Return original string as non-JSON and empty string for JSON
        }
    } else {
        return [nonJsonPart, undefined]; // Could be a json string but it's not complete, so return part before possible JSON
    }
}

export function formatChatMessage(message: IChatMessage): string {
    let content = message.content;
    if (message.contentMetadataArray) {
        if (message.contentMetadataArray.contentMetadata.length > 0) {
            // match this regex '\[\[#(\d+)\]\]' and replace each occurence with a link to the referenced content
            const regex = /\[\[#(\d+)\]\]/g;
            const matches = content.match(regex);
            if (matches) {
                for (const match of matches) {
                    const id = Number.parseInt(match.substring(3, match.length - 2));
                    const ref = message.contentMetadataArray.contentMetadata.find((c) => c.id === id);
                    const link = `<sup>${ref?.orderOfAppearance.toString() ?? ''}</sup>`;
                    content = content.replace(match, link);
                }
            }
        }
    }
    const [formattedContent] = extractJsonFromString(content);
    return formattedContent;
}
